import React, { Suspense } from 'react';
import { lazyLoad } from '../common/utils/lazy/lazyLoad';

const LazyApp = lazyLoad(() => import('./App'), 'app');

const App: React.FC = () => (
  <Suspense fallback={null}>
    <LazyApp />
  </Suspense>
);

export default App;
