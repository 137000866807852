import './i18n';
import './index.css';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as ReactDOMClient from 'react-dom/client';

import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import { Suspense } from 'react';
import App from './app/App.lazy';
import { createUpdateEvent } from './common/hooks/useServiceWorker';
import { Splash } from './layout/Splash/Splash';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// eslint-disable-next-line unicorn/prefer-query-selector
const root = document.getElementById('root');
const applicationInsightsKey: string = process.env
  .REACT_APP_APPLICATION_INSIGHTS_KEY as string;
const browserHistory = createBrowserHistory();
export const reactPlugin = new ReactPlugin();

let appInsights;
if (applicationInsightsKey) {
  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: applicationInsightsKey,
      enableAutoRouteTracking: true,
      autoTrackPageVisitTime: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      enableAjaxErrorStatusText: true,
      enableAjaxPerfTracking: true,
      enableUnhandledPromiseRejectionTracking: true,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });
  if (appInsights) {
    appInsights.loadAppInsights();
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
ReactDOMClient.createRoot(root).render(
  <Suspense fallback={<Splash />}>
    <App />
  </Suspense>,
);

serviceWorkerRegistration.register({
  onUpdate: registration => {
    if (registration?.waiting) {
      document.dispatchEvent(createUpdateEvent(registration.waiting));
    }
  },
});

const checkForUpdates = () => {
  serviceWorkerRegistration.checkForUpdate();

  // This is necessary because sometimes the app won't recognize it anymore.
  serviceWorkerRegistration.getWaitingServiceWorker().then(worker => {
    if (worker) {
      document.dispatchEvent(createUpdateEvent(worker));
    }
  });

  setTimeout(checkForUpdates, 60 * 1000 * 5);
};

// App checks every 5 minutes for updates:
checkForUpdates();

window.localStorage.fluentUIDebug = process.env.NODE_ENV !== 'production';
