import { t } from 'i18next';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useEvent, useTitle } from 'react-use';
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';
import { SHOW_CHANGELOG_SEARCH } from '../../serviceWorkerRegistration';

const APP_UPDATE_AVAILABLE_EVENT = 'app-update-available';
const APP_NAME = 'MNSpro Cloud';

export const createUpdateEvent = (serviceWorker: ServiceWorker) =>
  new CustomEvent<ServiceWorker>(APP_UPDATE_AVAILABLE_EVENT, {
    detail: serviceWorker,
  });

interface UseServiceWorker {
  isUpdateAvailable: boolean;
  update(): void;
  checkForUpdate(): void;
}

const reloadWithChangelog = () => {
  const url = new URL(window.location.href);
  url.searchParams.append(SHOW_CHANGELOG_SEARCH, 'true');

  window.location.href = url.href;
};

export const useServiceWorker = (): UseServiceWorker => {
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [waitingServiceWorker, setWaitingServiceWorker] =
    useState<ServiceWorker>();

  useTitle(
    isUpdateAvailable
      ? `[📣] ${APP_NAME} - ${t(
          'update_available_app_title',
          'Update verfügbar!',
        )}`
      : APP_NAME,
  );

  useEvent(
    APP_UPDATE_AVAILABLE_EVENT,
    event => {
      const customEvent = event as CustomEvent<ServiceWorker>;

      setIsUpdateAvailable(true);
      setWaitingServiceWorker(customEvent.detail);
    },
    document,
  );

  useEffect(() => {
    waitingServiceWorker?.addEventListener('statechange', event => {
      if (get(event?.target, 'state') === 'activated') {
        // If a new service worker was installed, a page reload will happen:
        reloadWithChangelog();
      }
    });
  }, [waitingServiceWorker]);

  return {
    isUpdateAvailable,
    update: () => {
      if (waitingServiceWorker) {
        waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
      }
    },
    checkForUpdate: () => {
      serviceWorkerRegistration.checkForUpdate();

      // This is necessary because sometimes the app won't recognize it anymore.
      serviceWorkerRegistration.getWaitingServiceWorker().then(worker => {
        if (worker) {
          document.dispatchEvent(createUpdateEvent(worker));
        }
      });
    },
  };
};
